.app-header {
  background-color: white;
  border-bottom: 1px solid  #F2F2F2; /* $equinor_LIGHT_GRAY;*/;
  height: 50px;
}

.brand {
  white-space: nowrap;
}

img {
  vertical-align: baseline;
}

.brand-logo {
  margin-left: 12px;
  margin-bottom: -2px;
  max-width: 100px;
  height: 50px;
}

.divider {
  border-left: 1px solid #F2F2F2; /* $equinor_LIGHT_GRAY;*/
  width: 1px;
  height: 30px;
  display: inline-block;
  margin-left: 20px; /* $brand-spacing; */
  margin-right: 20px; /* $brand-spacing; */
}

.loader {
  position: relative;
  left: 8px;
  bottom: 2px;
}
/* @include media-breakpoint-down(xs) {
  .brand-logo {
    max-width: 25vw;
  }
  .divider {
    margin-left: $brand-spacing * 0.3;
    margin-right: $brand-spacing * 0.3;
  }
  .app-logo {
    max-width: 40vw;
  }
} */

svg.menu-item {
  margin-top: -5px;
  margin-right: 6px;
}

