.flex-master {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-grow: 1;
  height: 0;
}

.resultsTableContainer {
  /* Supporting styling for virtual scrolling */
  overflow-y: hidden;
}

.resultsTableHeaderWrapper {
  /* Supporting styling for virtual scrolling */
  border-radius: 6px 0 0 0;
  border: 0;
  position: absolute;
  overflow-x: hidden;
  width: 0;
  z-index: 10;
}

.resultsTableHeader {
  /* Supporting styling for virtual scrolling */
  /* Aligns all the tables */
  table-layout: fixed;
  visibility: hidden;
  overflow-x: hidden;
  background-color: #fafafa;
  min-width: 700px;
  z-index: 10;
  height: 55px;
}

.resultsTableHeaderTableCell {
  /* Supporting styling for virtual scrolling */
  white-space: nowrap;
  overflow: hidden;
}

.resultsTable {
  /* Supporting styling for virtual scrolling */
  /* Aligns all the tables */
  table-layout: fixed;
}

.resultsTableHeaderOffset {
  /* Supporting styling for virtual scrolling */
  height: 55px;
}

.resultsTableRow {
  /* Supporting styling for virtual scrolling */
  min-width: 700px;
}

.page-header {
  flex: 0 0 auto;
  display: flex;
  padding-bottom: 3px;
  /* no grow no shrink */
  /* background: #535353;
  color: white;
  padding: 25px;
  font-size: 3em;
  font-weight: bold; */
}

.page-header-left {
  flex: 1 1 13%;
  display: flex;
}

.page-header-params {
  flex: 8 8 74%;
  padding : 10;
  display: flex;
}

.page-header-right {
  flex: 1 1 13%;
  display: flex;
}

.page-content {
  /* grow and shrink vertically */
  flex: 1 1 auto;

  /* new horizontal flex container */
  display: flex;
  flex-direction: row;

  /* IMPORTANT: Required for FireFox
      to ensure vertical sizing is constrained.
      Not sure why this is required - it should
      be inherited.
  */
  overflow: auto;
}

.sidebar-left {
  /* should stay fixed except for resizing  */
  flex: 0 0 auto;

  width: 50%;
  background: #ccc;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.splitter {
  /* should stay fixed size */
  flex: 0 0 auto;

  width: 15px;
  cursor: col-resize;
  background: #535353  center center no-repeat;
  /* Supporting styling for virtual scrolling */
  z-index: 10;
}

.main-content {
  /* grow & shrink horizontally */
  flex: 1 1 auto;

  overflow:auto;
  background: white;
  padding: 0px 20px 20px 20px;
  -webkit-overflow-scrolling: touch;
  /* Supporting styling for virtual scrolling */
  z-index: 10;
}

.filter-summary-text{
  display: flex;
  flex-flow: row wrap;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}
